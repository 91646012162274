@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #0a0908;
    color: #ffffff;
}


@font-face {
    font-family: 'poppins';
    src: url('../public/fonts/poppins/regular-poppins.ttf');
}

@font-face {
    font-family: 'm_poppins';
    src: url('../public/fonts/poppins/medium-poppins.ttf');
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    max-height: 90px;
    z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
